import React from 'react';
// import React, { useEffect } from 'react';
import Navbar from './components/nav/Navbar';
import Main from './components/main/Main';
import './App.css';

// import BodyLock from './components/BodyLock';

export default function App() {

  // useEffect(()=>{
  //   // window.addEventListener("scroll", (e) => {
  //   //   e.preventDefault();
  //   //   window.scrollTo(0, 0);
  //   // });

  //   // BodyLock;
  // }, [])

  return (
    <div className="App">
      <Navbar />
      <Main />
    </div>
  );
}


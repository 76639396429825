import React, { useState } from 'react';

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";

import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";

import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { TableContext } from "./plugins/TablePlugin";
// import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import TableCellActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import TableCellResizer from "./plugins/TableCellResizer";

import { LocalStoragePlugin } from "./plugins/LocalStoragePlugin";

import ExampleTheme from "./themes/ExampleTheme";
import './Lexical.css';
import "./styles.css";

function Placeholder() {
  return <div className="editor-placeholder">Enter some text...</div>;
}

export const EDITOR_NAMESPACE = "lexical-editor";
const content = localStorage.getItem(EDITOR_NAMESPACE);

const editorConfig = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ],
  editorState: content,
  namespace: EDITOR_NAMESPACE,
};

// type LexicalEditorProps = {
//   config: Parameters<typeof LexicalComposer>['0']['initialConfig'];
// };
const LexicalEditorProps = LexicalComposer.constructor.initialConfig;

export default function Editor() {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <TableContext>
        <div className="editor-container">
          <ToolbarPlugin />
          <TablePlugin />
          <TableCellResizer />
          {/* <div className="editor-inner"> */}
            <RichTextPlugin
              contentEditable={
                <div className="editor-input">
                  <div className="editor" ref={onRef}>
                    <ContentEditable className="ContentEditable__root" />
                  </div>
                </div>
              }
              // placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <TreeViewPlugin />
            <AutoFocusPlugin />
            <CodeHighlightPlugin />
            <ListPlugin />
            <CheckListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          </div>
        {/* </div> */}

        {floatingAnchorElem && (
          <>
            <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
          </>
        )}
      </TableContext>
      <LocalStoragePlugin namespace={editorConfig.namespace} />
    </LexicalComposer>
  );
}

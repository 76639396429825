import React, { Component } from 'react'
import Lexical from '../editor-lexical/Lexical'

import './Main.css';

import styled from 'styled-components';

const Main = styled.div`
  // width: 100%;
  // height: 40px;
  // border-bottom: 2px solid #f1f1f1;
  // padding: 0 20px;
  // display: flex;
  // justify-content: space-between;

  // .logo {
  //   padding: 12px 0;
  // }

  position: fixed;
  top:40px;
  z-index:0;
  width: calc(100vw - 0px);
`

export default class main extends Component {
  render() {
    return (
      // <div className="main">
      <Main classname="">
        <Lexical />
      </Main>
      // </div>
    )
  }
}
